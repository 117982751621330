import React, { useEffect, useState } from "react";
import "./css/play.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Question from "./questions";
import { getCompetitions, updateScoreCompetitions, getAllOpponent } from "../services/home";
import ChampionLossGames from "./championshipLoss";
import { useTranslation } from "react-i18next";

const PlayGames = () => {
    const [data, setData] = useState(false);
    const [timeLeft, setTimeLeft] = useState({
        minutes: 0,
        seconds: 0
    });
    const location = useLocation();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [userScore, setUserScore] = useState(0);
    const [opponentScore, setOpponentScore] = useState(0);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [quizData, setQuizData] = useState([]);
    const [quizResponse, setQuizResponse] = useState(0);
    const [isScoreCalculated, setIsScoreCalcualted] = useState(false);
    const [opponent, setOpponent] = useState();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [timeStart, setTimeStart] = useState(new Date().getTime());
    // const [remainingTime, setRemainingTime] = useState(0);

    const [duration, setDuration] = useState(0);
    useEffect(() => {
        if (location.state && location.state.start_date_time) {
            setDuration(location.state.duration);
        }
    }, [location.state, duration]);

    const [timeRemaining, setTimeRemaining] = useState(duration);

    useEffect(() => {
        if (timeLeft) {
            const interval = setInterval(() => {
                setTimeLeft((prevState) => {
                    if (prevState.minutes === 0 && prevState.seconds === 0) {
                        stopTimer();
                        return prevState;
                    }

                    const secondsLeft = (60 + (prevState.seconds - 1)) % 60;
                    const minutesLeft =
                        prevState.seconds === 0 ? prevState.minutes - 1 : prevState.minutes;

                    return {
                        seconds: secondsLeft,
                        minutes: minutesLeft
                    };
                });
            }, 1000);

            const stopTimer = () => {
                clearInterval(interval);
                setQuizCompleted(true);
            };

            return () => clearInterval(interval);
        }
    }, [timeLeft]);

    const formatTime = (time) => {
        const hours = Math.floor(time / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        // ${hours}:
        return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    const calculateProgress = () => {
        const elapsed = duration - timeRemaining;
        const progress = (elapsed / duration) * 100;
        return 190 - (190 * progress) / 100;
    };

    useEffect(() => {
        setTimeRemaining(duration);

        getAllOpponent()
            .then((res) => {
                setOpponent(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (duration) {
            console.log(duration);
            setTimeLeft({
                minutes: Math.floor(duration / 60),
                seconds: duration % 60
            });
        }
    }, [duration]);

    useEffect(() => {
        getCompetitions(location.state.random_questions, localStorage.getItem("selectedLanguage"))
            .then((res) => {
                setQuizResponse(res.length);
                const quizData =
                    res &&
                    res.map((res, index) => ({
                        qno: index + 1,
                        question: res.title,
                        id: res.id,
                        quiz_id: res.quizId,
                        image: res.image,
                        type: res.type,
                        options: [
                            { key: "1", value: res.option_1 },
                            { key: "2", value: res.option_2 },
                            { key: "3", value: res.option_3 },
                            { key: "4", value: res.option_4 }
                        ],
                        correctAnswer: res.correctAnswer
                    }));
                // console.log(quizData)
                setQuizData(quizData);
                setData(true);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const playSound = (answer) => {
        const audio = new Audio(`/sounds/${answer ? "correct" : "wronganswer"}.mp3`); // Use the absolute path to the sound file
        audio.play();
    };

    const calculateDifferenceInSeconds = (calstartTime, calendTime) => {
        const startDateTime = new Date(calstartTime).getTime();
        const endDateTime = new Date(calendTime).getTime();
        const difference = endDateTime - startDateTime;
        const differenceInSeconds = Math.floor(difference / 1000); // Convert milliseconds to seconds
        return differenceInSeconds;
    };

    useEffect(() => {
        if (quizCompleted) {
            if (userScore === opponentScore) {
                setOpponentScore(opponentScore + 1);
            }
            setIsScoreCalcualted(true);

            const timeTotal = calculateDifferenceInSeconds(timeStart, new Date().getTime());
            updateScoreCompetitions(location?.state.id, {
                right: userScore * 1,
                wrong: location.state.random_questions - userScore,
                timeTotal: timeTotal
            }).then(() => {
                setIsScoreCalcualted(true);
            });
        }
    }, [quizCompleted, timeStart]);

    const handleAnswer = (isCorrect, isOpponentCorrect) => {
        if (isCorrect) {
            setUserScore(userScore + 1);
        }
        if (isOpponentCorrect) {
            setOpponentScore(opponentScore + 1);
        }

        const totalQuestion = quizData.length - 1;
        if (totalQuestion <= currentQuestion) {
            setQuizCompleted(true);
            // })
        } else {
            const labelForAnswer1 = document.querySelector(
                `label[for="answer${quizData[currentQuestion].correctAnswer}"]`
            );
            if (isCorrect === false) {
                // Check if the label element is found
                if (labelForAnswer1) {
                    // Add a class to the label element
                    labelForAnswer1.classList.add("correct");
                }
                playSound(false);
            } else {
                playSound(true);
            }
            setTimeout(() => {
                setCurrentQuestion(currentQuestion + 1);
                if (labelForAnswer1) {
                    // Add a class to the label element
                    labelForAnswer1.classList.remove("correct");
                }
            }, 500);
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };
    return (
        <>
            {quizCompleted ? (
                <ChampionLossGames
                    userScore={userScore}
                    opponentScore={opponentScore}
                    isScoreCalculated={isScoreCalculated}
                    state={location?.state}
                />
            ) : (
                <div className="home">
                    <div className="main">
                        <div className="page-header-score">
                            <div className="container box-wrapper adjestment">
                                <div className="row gx-0">
                                    <div className="col-4">
                                        <div className="quiz-player">
                                            <div className="quiz-player-avatar">
                                                {/* <img src={location?.state?.players?.user?.image ? location?.state?.players?.user?.image : "/image/image 4.png"} alt="Avatar" /> */}
                                            </div>
                                            <div className="quiz-player-info text-start ms-2 mt-2">
                                                {/* <p className="mb-2 role-name">{t('26')}</p> */}
                                                {/* <p className="mb-0 fw-bold real_score" id="real_score_start">0</p> */}
                                                <p
                                                    className="mb-0 fw-bold"
                                                    id="real_score_show"
                                                ></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="countdown-clock">
                                            <svg className="progress-ring" width="140" height="140">
                                                <circle
                                                    className="progress-ring__circle"
                                                    strokeWidth="4"
                                                    fill="transparent"
                                                    r="58"
                                                    cx="68"
                                                    cy="68"
                                                    style={{
                                                        strokeDasharray: "314",
                                                        strokeDashoffset: calculateProgress(),
                                                        stroke: "#fe9e44",
                                                        fill: "rgb(33 37 41 / 56%)"
                                                    }}
                                                />
                                            </svg>
                                            <span>{`${timeLeft.minutes < 10 ? "0" : ""}${
                                                timeLeft.minutes
                                            }:${timeLeft.seconds < 10 ? "0" : ""}${
                                                timeLeft.seconds
                                            }`}</span>
                                        </div>
                                        {/* <div className="countdown" id="countdown">
                                    <span id="countdown-number">{formatTime(remainingTime)}</span> 
                                    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle opacity="0.5" cx="35" cy="35" r="33" fill="#212529"
                                        style={{
                                          strokeDasharray: `${circumference}px`,
                                          strokeDashoffset: `${initialOffset}px`,
                                          strokeLinecap: "round",
                                          strokeWidth: "4px",
                                          stroke: "#fe9e44",
                                          fill: "#212529",
                                          animation: "countdown 50s linear infinite forwards",
                                        }}
                                        />
                                    </svg>

                                </div> */}
                                    </div>
                                    {opponent ? (
                                        <></>
                                    ) : (
                                        // <div className="col-5">
                                        //     <div className="quiz-player">
                                        //         <div className="quiz-player-info text-end me-2 mt-2">
                                        //             <p className="mb-2 role-name">{opponent.opponent?.name}</p>
                                        //             <p className="mb-0 fw-bold real_score" id="fake_score_start">0</p>
                                        //             <p className="mb-0 fw-bold" id="fake_score_show"></p>
                                        //         </div>
                                        //         <div className="quiz-player-avatar">
                                        //             <img src={opponent.opponent?.image ? opponent.opponent?.image : "/image/image 4.png"} alt="Avatar"/>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <p className='available'>Sorry, the quiz is not available at the moment.</p> */}
                        {data && quizData.length > 0 && !quizCompleted ? (
                            <Question
                                questionData={quizData[currentQuestion]}
                                onAnswer={handleAnswer}
                                totalque={quizResponse}
                            />
                        ) : (
                            ""
                        )}

                        <div className="container box-wrapper">
                            <div className="pt-5 pb-4 text-center">
                                <NavLink
                                    to="#"
                                    onClick={handleGoBack}
                                    className="btn btn-sm button-back"
                                >
                                    {" "}
                                    <img src="/image/arrow_left_alt.png" alt="back" /> {t("30")}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PlayGames;
