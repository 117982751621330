import React, { useState, useEffect } from "react";
import "./css/screen3.css";
import { getHistory } from "../services/home";
import { useNavigate } from "react-router-dom";
import { findLevel } from "../utils/levels";
import moment from "moment";
import { useTranslation } from "react-i18next";

const History = () => {
    const [historyList, setHistoryList] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentLanguage = localStorage.getItem("selectedLanguage");

    useEffect(() => {
        getHistory(currentLanguage)
            .then((res) => {
                setHistoryList(
                    res.map((quiz) => ({
                        ...quiz,
                        level: findLevel(Number.parseInt(quiz.levelId))?.name
                    }))
                );
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentLanguage]);

    return (
        <div className="history">
            <div className="main">
                <div className="container-fluid box-wrapper difficult">
                    <div className="page-header pb-5">
                        <div className="box-wrapper mx-auto text-center position-relative header-container">
                            <button
                                type="button"
                                className="icon-button"
                                onClick={() => navigate(-1)}
                            >
                                <img src="/image/back.png" className="arrow" alt="back" />
                            </button>
                            <span className="levels-title">{t("38")}</span>
                        </div>
                    </div>
                    <div className="card-simple-outer">
                        <ul className="history-list-wrapper">
                            {historyList.map((history) => {
                                return (
                                    <li key={history.id} className="history-list">
                                        <div className="history-list-pict">
                                            <img src={history.imageLink} alt="Quiz" />
                                        </div>
                                        <div className="history-list-info">
                                            <h4 className="history-list-info-title">
                                                {history.quizName}
                                            </h4>
                                            <p>
                                                <b>{t("39")}:</b> {history.level}{" "}
                                                <span>
                                                    <b>{t("6")}:</b> {history.totalPoints}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="history-event">
                                            <span>
                                                <img src="/image/event.png" alt="Tag" />
                                                {moment(history.createdAt).format("YYYY-MM-DD")}
                                            </span>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default History;
